<template>
  <div v-if="!isPreloader" class="table-responsive relations-table">

    <base-table
        v-if="shownData.length"
        :columns="fileReferencesFields"
        :rows="shownData"
    />

    <table-pagination
        v-if="shownData.length"
        :totalPages="1"
        :countOnPage="countOnPage"
        :total="rowData.length"
        :count="countOnPage > shownData.length ? shownData.length : countOnPage"
        @changePage="changePage"
    />

    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>

  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed, onBeforeMount } from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import router from "@/router";
import rusData from '@/mixins/rusData';
import standartError from "@/mixins/standartError";
import useErrors from "@/mixins/useErrors";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "file-references-table",
  components: {
    BaseTable,
    VPagination,
    Preloader,
    TablePagination,
  },
  setup() {
    const store = useStore(),
      {getRusData} = rusData(), 
      { setErrors, clearCustomErrors } = useErrors();
    const countOnPage = ref(15);
    const isPreloader = ref(false);

    const fileReferencesList = computed(
      () => store.state.references.fileReferencesList
    );
    
    const rowData = computed(() => {
      return fileReferencesList.value.map((file) => {
        return {
          id: file.id,
          name: file.name,
          createdAt: getRusData(file.created_at),
        };
      });
    });

    const checkFile = (id) => {
      let profile = fileReferencesList.value.filter((file) => {
        return file.id == id
      });
      const currentProfile = JSON.parse(JSON.stringify(profile[0]))
      isPreloader.value = true;
      store.dispatch("analytics/calculateProfile", currentProfile)
        .then(() => {
            isPreloader.value = false
            router.push("/references/profiles");
          })
        .catch(standartError(setErrors, clearCustomErrors, isPreloader));
     };

    const shownData = computed(() => {
        return rowData.value ? rowData.value.slice(pageDataStart.value, pageDataStart.value + countOnPage.value) : [];
    });

    const pageDataStart = ref(0);

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const fileReferencesFields = [
        {
          field: "short",
          headerName: "Название",
          originField: 'name',
        },
        {
          field: "createdAt",
          headerName: "Дата загрузки",
        },
        {
          field: "button",
          headerName: "",
          text: "Рассчитать",
          callback: checkFile,
        },
        {
          headerName: '',
          field: 'delete_reference_excel',
        },
      ];

    onBeforeMount(() => {
      isPreloader.value = true;
      store.dispatch("references/getReferencesFilesList").then(() => isPreloader.value = false);
    });

    return {
      isPreloader,
      rowData,
      countOnPage,
      getRusData,

      changePage,
      pageDataStart,
      shownData,
      fileReferencesFields
    };
  },
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 8px;
}
</style>
